import '@fortawesome/fontawesome-free-webfonts';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';

var jquery = require("jquery");
window.$ = window.jQuery = jquery; // notice the definition of global variables here
require("jquery-ui-dist/jquery-ui.js");

import {TimelineMax} from 'gsap'

//-------------------------   Vars functions   --------------------------- //

var dataurl = "https://prorail.deeltbeeld.nl";

// Vars button indien er geen internetverbinding is

var txt_visualisatie = "VISUALISATIE";
var txt_tekstueel = "TEKSTUEEL";
var txt_colofon = "COLOFON";
var txt_delen = "DELEN";
var txt_terug_naar_home = "TERUG NAAR HOME";
var txt_presentatie_modus = "PRESENTATIE MODUS";
var txt_fullscreen = "FULLSCREEN";

var txt_hoofdplaat = "Hoofdplaat";
var txt_inwinnen = "Inwinnen";
var txt_gebruik = "Gebruik";

var homepageid = 16;
var infoid = 17;
var readerid = 15;

var language = 1;

// Variabelen als startup

var elem = document.documentElement;

var maxzoom = 4;
var zoomamount = 50;

var startwidth = 100;

var currentzoom = 100;
var zoomposition = 0;
var presentation_modus = 0;
var fullscreen = 0;
var fullzoomout = 0;

var isworking = 0;

var startX = 0;
var startY = 0;
var hascontent = 0;
var activeMarker = 0;
var resizeId;
var startingleft = 0;
var startingtop = 0;

var currentImage = 2;
var currentorientation = "landscape";
// Variabelen van ID's van HTML tags

var homepage =  document.getElementById("homepage");	
var info =  document.getElementById("info");
var info_menu =  document.getElementById("info_menu");
var sharebox = document.getElementById("sharebox");

var main = document.getElementById("main");
var fullimage = document.getElementById("fullimage");
var mainimg = document.getElementById("main-img");
var hoverimg = document.getElementById("hover-img");
var img_wrapper = document.getElementById("img-wrapper");
var marker_wrapper = document.getElementById("marker-wrapper");
var zoom = document.getElementById("zoom");
var menubutton = document.getElementById("menubutton");
var content = document.getElementById("content");
var contentcontainer =  document.getElementById("contentcontainer");
var nextitem = document.getElementById("nextitem");
var previtem = document.getElementById("previtem");
var gototop = document.getElementById("gototop");
var closecontent = document.getElementById("closecontent");
var naar_hoofdplaat = document.getElementById("naar_hoofdplaat");
var naar_inwinnen = document.getElementById("naar_inwinnen");
var naar_gebruik = document.getElementById("naar_gebruik");
var pages = document.getElementById("pages");
var sidebar = document.getElementById("sidebar");


//-------------------------   Startup functions   --------------------------- //


$(function() {


	var userLang = navigator.language || navigator.userLanguage;
	var cookie_exists = document.cookie.match(/^(.*;)?\s*lang\s*=\s*[^;]+(.*)?$/);


	if(userLang.substring(0, 2).toLowerCase() == "nl" && cookie_exists == null)
	{
		language = 1; // Nederlands laden
		document.cookie = "lang="+language;
		$('#langbutton img.small').attr("src",dataurl+"/img/flag-uk.png");
		$('#langbutton img.large').attr("src",dataurl+"/img/flag-nl.png");

		$('.input_lang').val(language);

		$('#fullimage').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik.jpg");
		$('#main-img').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik.jpg");

	}
	else if(cookie_exists == null)
	{	
		language = 2; // Engelse laden
		document.cookie = "lang="+language;		
		$('#langbutton img.small').attr("src",dataurl+"/img/flag-nl.png");
		$('#langbutton img.large').attr("src",dataurl+"/img/flag-uk.png");

		$('.input_lang').val(language);

		$('#fullimage').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-eng.jpg");
		$('#main-img').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-eng.jpg");

	}
	else
	{
		language = getCookie("lang");
		$('.input_lang').val(language);

		if(language == "1")
		{
			$('#langbutton img.small').attr("src",dataurl+"/img/flag-uk.png");
			$('#langbutton img.large').attr("src",dataurl+"/img/flag-nl.png");

			$('#fullimage').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik.jpg");
			$('#main-img').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik.jpg");

		}
		else
		{
			$('#langbutton img.small').attr("src",dataurl+"/img/flag-nl.png");
			$('#langbutton img.large').attr("src",dataurl+"/img/flag-uk.png");

			$('#fullimage').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-eng.jpg");
			$('#main-img').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-eng.jpg");
		}
	}
	

	// Teksten laden op de homepaghe
	loaderStatus('show');


	function preloadImages(urls, allImagesLoadedCallback){
    var loadedCounter = 0;
	var toBeLoadedNumber = urls.length;
	  urls.forEach(function(url){
	    preloadImage(url, function(){
	        loadedCounter++;
	        console.log('Number of loaded images: ' + loadedCounter);
	      if(loadedCounter == toBeLoadedNumber){
	        allImagesLoadedCallback();
	      }
	    });
	  });
	  function preloadImage(url, anImageLoadedCallback){
	      var img = new Image();
	      img.onload = anImageLoadedCallback;
	      img.src = url;
	  }
	}

	// Let's call it:
	preloadImages([
	    'https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik.jpg',
	  	'https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-markerb-hover.jpg',
	  	'https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-eng.jpg'
	], function(){
	    console.log('All images were loaded');
	    load_content();
	});


	function load_content() {
		
		loadcontent(homepageid,'hometext');
		loadbtntxt();

		$.when(checkorientation())
		.then(dragit())
		.then(positionimagewrapper())
		.then(positionmarkers())
		.then(initialload());

		currentorientation = getorientation();

		var orientation = getorientation();
		if(orientation == "portrait")
			$('#zoom .minus').css({opacity:"0.5", cursor: "default"})


		$( img_wrapper ).mousedown(function() {
			$(marker_wrapper).css("cursor", "grabbing");
		});

		$( img_wrapper ).mouseup(function() {
			$(marker_wrapper).css("cursor", "grab");
		});
	}


	// Scroll / resize functies
	 $(window).resize(function() {


		 	var delayInMilliseconds = 500; //1 second
	  		
	  		clearTimeout(doit);
			var doit = setTimeout(function() {

					$.when(checkorientation())
					.then(dragit())
					.then(positionimagewrapper())    // Dit zorgt voor een fout bij een inzoom
					.then(positionmarkers())
					.then(initialload());


					if(activeMarker != 0)
					{
						wrappermover(activeMarker);
					}

					var orientation = getorientation();
					if(orientation == "portrait")
						$('#zoom .minus').css({opacity:"0.5", cursor: "default"})
					else
						$('#zoom .minus').css({opacity:"1", cursor: "zoomout"})


					console.log('resize');

			}, delayInMilliseconds);
		
	});

	// SCROLL TO TOP FUNCTIES	
	$("#content").scroll(function() {
	    $('#gototop').css("opacity", "1");

	    var height = $("#content").scrollTop();
	    if(height === 0)
	   	 $('#gototop').css("opacity", "0");
	});


	$("#info").scroll(function() {
	    $('#gototop_reader').css("opacity", "1");

	    var height = $("#info").scrollTop();
	    if(height === 0)
	   	 $('#gototop_reader').css("opacity", "0");
	});


});


function getCookie(k){
	var v=document.cookie.match('(^|;) ?'+k+'=([^;]*)(;|$)');return v?v[2]:null
}

//-------------------------   Begin documentfuncties   --------------------------- //

	if (document.addEventListener)
	{
	 document.addEventListener('fullscreenchange', onFullScreenChange, false);
	 document.addEventListener('mozfullscreenchange', onFullScreenChange, false);
	 document.addEventListener('MSFullscreenChange', onFullScreenChange, false);
	 document.addEventListener('webkitfullscreenchange', onFullScreenChange, false);
	}

	function onFullScreenChange()
	{
		 if (document.webkitIsFullScreen === false)
		 {
			switch_btn_off('fs_mode');
		 }
		 else if (document.mozFullScreen === false)
		 {
			switch_btn_off('fs_mode');
		 }
		 else if (document.msFullscreenElement === false)
		 {
			switch_btn_off('fs_mode');
		 }
	}


	function loaderStatus(status)
	{
		if(status == "hide")
			$('#loader').hide();
		else
			$('#loader').show();	
	}

	function menustatus(status)
	{
		if(status == "show")
		{
			var tl = new TimelineMax();
			tl.to(sidebar,0.5, {css:{right: "0px"}});
		}
		else
		{
			var tl = new TimelineMax();
			tl.to(sidebar,0.5, {css:{right: "-350px"}});
		}
	}


	function switch_btn_on(classname)
	{
		const tl = new TimelineMax();
		tl.to('.'+classname+' .switch',0.5, {css:{"margin-left": "17px",opacity: "1.0"}});
	}

	function switch_btn_off(classname)
	{
		const tl = new TimelineMax();
		tl.to('.'+classname+' .switch',0.5, {css:{"margin-left": "3px",opacity: "0.5"}});
	}


	function showinfo(myid,myclass)
	{
		var tl = new TimelineMax();

		tl.to('.txtcontainer',0.5, {css:{opacity:0, display: 'block'}}, '-=0.5')
		.call(loadcontent,[myid,'txtcontainer'], '-=0.5')
		.to('.txtcontainer',0.5, {css:{opacity:1, display: 'block'}})
		.to(info,0, {css:{ "z-index": 1030}}, '-=0.5')
		.to(info,1, {css:{opacity:1}}, '-=0.5')


		if(myid != 17)
		{
			tl.to(info_menu,1, {css:{opacity:1}}, '-=0.5')
			.to(info_menu,0, {css:{ "z-index": 1040}}, '-=0.5');
		}
	}


	function closeinfo()
	{
		var tl = new TimelineMax();

		tl.to(info,0.5, {css:{opacity:0}})
		.to(info,0.5, {css:{ "z-index": 1}}, '-=0.5')
		.to(info_menu,1, {css:{opacity:0}}, '-=0.5')
		.to(info_menu,0, {css:{ "z-index": 0}}, '-=0.5')
		.to('.txtcontainer',0.5, {css:{opacity:0, display: 'none'}});
	}


	function showshare()
	{

		$('.returnmessage').html("");

    	$('.txt_naam_ontvanger').val("");
    	$('.txt_email_ontvanger').val("");
    	$('.txt_naam').val("");
    	$('.txt_email').val("");


		var tl = new TimelineMax();
		tl.to(sharebox,0.5, {css:{top: "0px"}});
	}

	function closeshare()
	{
		var tl = new TimelineMax();
		tl.to(sharebox,0.5, {css:{top: "-450px"}});
	}



	function openFullscreen() 
	{
	  if (elem.requestFullscreen) {
	    elem.requestFullscreen();
	  } else if (elem.mozRequestFullScreen) { /* Firefox */
	    elem.mozRequestFullScreen();
	  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
	    elem.webkitRequestFullscreen();
	  } else if (elem.msRequestFullscreen) { /* IE/Edge */
	    elem.msRequestFullscreen();
	  }
	}

	function closeFullscreen() 
	{
	  if (document.exitFullscreen) {
	    document.exitFullscreen();
	  } else if (document.mozCancelFullScreen) { /* Firefox */
	    document.mozCancelFullScreen();
	  } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
	    document.webkitExitFullscreen();
	  } else if (document.msExitFullscreen) { /* IE/Edge */
	    document.msExitFullscreen();
	  }
	}


	function letsgo()
	{
		var tl = new TimelineMax();
		tl.to(homepage,0.5, {css:{opacity:0, 'z-index':0}});

		var tl = new TimelineMax();
		tl.to('.mapitem',0.5, {css:{display: 'block'}});

	}

	
	function gotohome()
	{
		var tl = new TimelineMax();
		tl.add(closeinfo)
		.to(homepage,0.5, {css:{opacity:1, 'z-index':1030}});

		var tl = new TimelineMax();
		tl.to('.mapitem',0.5, {css:{display: 'none'}});

	}

	function share()
	{

		var receiver_name = $("#receiver_name").val();
		var receiver_email = $("#receiver_email").val();
		var sender_name = $("#sender_name").val();
		var sender_email = $("#sender_email").val();
		var thislanguage = getCookie("lang");

		$.ajax({
        url: dataurl+"/sendmail.php",
        type: 'POST',
        data: { action: "share", receiver_name: receiver_name, receiver_email: receiver_email, sender_name: sender_name, sender_email: sender_email, language: thislanguage },
        // dataType: 'json', // added data type
        success: function(res) {
        	language = getCookie("lang");

        	if(language == "2")
        		$('.returnmessage').html("Thanks for sharing!");
        	else
        		$('.returnmessage').html("Bedankt voor het delen!");
        }, error: function() {

        	language = getCookie("lang");

        	if(language == "2")
            	$('.returnmessage').html("Something went wrong. Please try again.");
	    	else
	        	$('.returnmessage').html("Er ging iets mis, probeer opnieuw.");

        
        }
    });
	}

	function changelanguage(lang)
	{
		language = getCookie("lang");

		if(language == "2")
		{
			language = 1;
			document.cookie = "lang="+language;
			$('#langbutton img.small').attr("src",dataurl+"/img/flag-uk.png");
			$('#langbutton img.large').attr("src",dataurl+"/img/flag-nl.png");

			$('.input_lang').val(language);

			$('#fullimage').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik.jpg");
			$('#main-img').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik.jpg");

		}
		else
		{
			language = 2;
			document.cookie = "lang="+language;	
			$('#langbutton img.small').attr("src",dataurl+"/img/flag-nl.png");
			$('#langbutton img.large').attr("src",dataurl+"/img/flag-uk.png");

			$('.input_lang').val(language);

			$('#fullimage').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-eng.jpg");
			$('#main-img').attr("src", "https://prorail.deeltbeeld.nl/dataimg/prorail-5d-drieluik-eng.jpg");

		}

		// Teksten laden op de homepaghe
		loadbtntxt();
		loadcontent(homepageid,'hometext');
	
	}

//-------------------------   Eind documentfuncties   --------------------------- //


//-------------------------   Begin textfuncties   --------------------------- //

function loadbtntxt()
{
    $.ajax({
        url: dataurl+"/content.php?type=buttons&lang="+language,
        type: 'GET',
        // dataType: 'json', // added data type
        success: function(res) {

         	var obj = JSON.parse(res);
                  	
        	$('.txt_visualisatie').html(obj.txt_visualisatie);
        	$('.txt_tekstueel').html(obj.txt_tekstueel);
        	$('.txt_colofon').html(obj.txt_colofon);
        	$('.txt_delen').html(obj.txt_delen);
        	$('.txt_terug_naar_home').html(obj.txt_terug_naar_home);
        	$('.txt_presentatie_modus').html(obj.txt_presentatie_modus);
        	$('.txt_fullscreen').html(obj.txt_fullscreen);
        	$('.txt_hoofdplaat').html(obj.txt_hoofdplaat);
        	$('.txt_inwinnen').html(obj.txt_inwinnen);
        	$('.txt_gebruik').html(obj.txt_gebruik);

        	$('.txt_deel_deze_website').html(obj.txt_deel_deze_website);
        	$('.txt_verstuur').html(obj.txt_verstuur);
s
        	$('.txt_naam_ontvanger').attr("placeholder",obj.txt_naam_ontvanger);
        	$('.txt_email_ontvanger').attr("placeholder",obj.txt_email_ontvanger);
        	$('.txt_naam').attr("placeholder",obj.txt_naam);
        	$('.txt_email').attr("placeholder",obj.txt_email);

        }, error: function() {
           // Standaard teksten instellen indien verbinding niet werkt

           	$('.txt_visualisatie').html(txt_visualisatie);
        	$('.txt_tekstueel').html(txt_tekstueel);
        	$('.txt_colofon').html(txt_colofon);
        	$('.txt_delen').html(txt_delen);
        	$('.txt_terug_naar_home').html(txt_terug_naar_home);
        	$('.txt_presentatie_modus').html(txt_presentatie_modus);
        	$('.txt_fullscreen').html(txt_fullscreen);
        	$('.txt_hoofdplaat').html(txt_hoofdplaat);
        	$('.txt_inwinnen').html(txt_inwinnen);
        	$('.txt_gebruik').html(txt_gebruik);
 	
 			$('.txt_deel_deze_website').html(obj.txt_deel_deze_website);
   	        $('.txt_verstuur').html(obj.txt_verstuur);

        	$('.txt_naam_ontvanger').attr("placeholder",obj.txt_naam_ontvanger);
        	$('.txt_email_ontvanger').attr("placeholder",obj.txt_email_ontvanger);
        	$('.txt_naam').attr("placeholder",obj.txt_naam);
        	$('.txt_email').attr("placeholder",obj.txt_email);

        }
    });


    $.ajax({
        url: dataurl+"/content.php?type=markers&lang="+language,
        type: 'GET',
        // dataType: 'json', // added data type
        success: function(res) {

         	var obj = JSON.parse(res);
                  	
        	$('#marker1').html(obj.markernumbers[0]);
        	$('#marker2').html(obj.markernumbers[1]);
        	$('#marker3').html(obj.markernumbers[2]);
        	$('#marker4').html(obj.markernumbers[3]);
        	$('#marker5').html(obj.markernumbers[4]);
        	$('#marker6').html(obj.markernumbers[5]);
        	$('#marker7').html(obj.markernumbers[6]);
        	$('#marker8').html(obj.markernumbers[7]);
        	$('#marker9').html(obj.markernumbers[8]);
        	$('#marker10').html(obj.markernumbers[9]);
        	$('#marker11').html(obj.markernumbers[10]);
        	$('#marker12').html(obj.markernumbers[11]);
        	$('#marker13').html(obj.markernumbers[12]);
        	$('#marker14').html(obj.markernumbers[13]);

        }, error: function() {
           // Standaard teksten instellen indien verbinding niet werkt

          
        }
    });
}

function loadcontent(id,container)
{	
	var content =  document.getElementById(container);

	if(id == 15 || id == "15")
	{
	
		$.ajax({
	        url: dataurl+"/content.php?type=txt&lang="+language+"&id="+id,
	        type: 'GET',
	        // dataType: 'json', // added data type
	        success: function(res) {
	            var obj = JSON.parse(res);
	           	content.innerHTML = "<h3>"+obj.subtitle+"</h3>";
	            content.innerHTML += "<h2>"+obj.title+"</h2>";
	            content.innerHTML += "<p>"+obj.text+"</p>";

				var markerstoload = ["1","2","3","4","5","6"];
				var newcontent_title = new Array();
				var newcontent_subtitle = new Array();
				var newcontent_text = new Array();
				var newcontent_image = new Array();
			
				var counter = markerstoload.length;

				markerstoload.forEach(function(item, index) {

						$.ajax({
					        url: dataurl+"/content.php?type=txt&id="+item,
					        type: 'GET',
					        async: false,
					        // dataType: 'json', // added data type
					        success: function(res) {
					            var obj = JSON.parse(res);

					           newcontent_subtitle[item] = obj.subtitle;
					           newcontent_title[item] = obj.title;
					           newcontent_text[item] = obj.text;
					           newcontent_image[item] = obj.image;

					        }, error: function() {
					        }
					    });  // einde ajax


				    	setTimeout(function() {
				        counter -= 1;
				        if ( counter === 0)
				        {				        	
				        	content.innerHTML += '<div class="row"><div class="col-lg-2 text-center"><img src="'+dataurl+'/upload/'+newcontent_image[1]+'" class="rounded-circle img-fluid"></div><div class="col-lg-10"><h3>'+newcontent_subtitle[1]+'</h3><h1>'+newcontent_title[1]+'</h1><p>'+newcontent_text[1]+'</p</div></div><div class="txtdivider"></div>';	
				        	content.innerHTML += '<div class="row"><div class="col-lg-2 text-center"><img src="'+dataurl+'/upload/'+newcontent_image[2]+'" class="rounded-circle img-fluid"></div><div class="col-lg-10"><h3>'+newcontent_subtitle[2]+'</h3><h1>'+newcontent_title[2]+'</h1><p>'+newcontent_text[2]+'</p</div></div><div class="txtdivider"></div>';	
				        	content.innerHTML += '<div class="row"><div class="col-lg-2 text-center"><img src="'+dataurl+'/upload/'+newcontent_image[3]+'" class="rounded-circle img-fluid"></div><div class="col-lg-10"><h3>'+newcontent_subtitle[3]+'</h3><h1>'+newcontent_title[3]+'</h1><p>'+newcontent_text[3]+'</p</div></div><div class="txtdivider"></div>';	
				        	content.innerHTML += '<div class="row"><div class="col-lg-2 text-center"><img src="'+dataurl+'/upload/'+newcontent_image[4]+'" class="rounded-circle img-fluid"></div><div class="col-lg-10"><h3>'+newcontent_subtitle[4]+'</h3><h1>'+newcontent_title[4]+'</h1><p>'+newcontent_text[4]+'</p</div></div><div class="txtdivider"></div>';	
				        	content.innerHTML += '<div class="row"><div class="col-lg-2 text-center"><img src="'+dataurl+'/upload/'+newcontent_image[5]+'" class="rounded-circle img-fluid"></div><div class="col-lg-10"><h3>'+newcontent_subtitle[5]+'</h3><h1>'+newcontent_title[5]+'</h1><p>'+newcontent_text[5]+'</p</div></div><div class="txtdivider"></div>';	
				        	content.innerHTML += '<div class="row"><div class="col-lg-2 text-center"><img src="'+dataurl+'/upload/'+newcontent_image[6]+'" class="rounded-circle img-fluid"></div><div class="col-lg-10"><h3>'+newcontent_subtitle[6]+'</h3><h1>'+newcontent_title[6]+'</h1><p>'+newcontent_text[6]+'</p</div></div><div class="txtdivider"></div>';	
				      
				        	$('.infobtn').mousedown(function() { 
							    // insertAfter 
							    var title = $(this).attr('title');
							    if(!document.getElementById('extrainfo_'+id+''))
								    $('<div class="extrainfo" id="extrainfo_'+id+'">'+title+'</p>').insertAfter(this); 
								 else
								 	$('#extrainfo_'+id+'').remove();

							}); 


				        }	
				    }, item);

				});
				return "done";
	        }, error: function() {
	            var htmlcontent = "Er is een fout opgetreden. Herlaad de pagina of neem contact met ons op."
	            content.innerHTML = htmlcontent;
	           	return "done";

	        }
	    });

	}
	else
	{
		$.ajax({
	        url: dataurl+"/content.php?type=txt&lang="+language+"&id="+id,
	        type: 'GET',
	        // dataType: 'json', // added data type
	        success: function(res) {
	            var obj = JSON.parse(res);
	           	content.innerHTML = "<h3>"+obj.subtitle+"</h3>";
	            content.innerHTML += "<h2>"+obj.title+"</h2>";
	            content.innerHTML += "<p>"+obj.text+"</p>";
	            return "done";


	        }, error: function() {
	            var htmlcontent = "Er is een fout opgetreden. Herlaad de pagina of neem contact met ons op."
	            content.innerHTML = htmlcontent;
	            return "done";

	        }
	    });
	}


}


//-------------------------   Eind textfuncties   --------------------------- //


//-------------------------   Begin mapfuncties   --------------------------- //
	function initialload()
	{	

		// Buttons en teksten hiden / tonen

		var tl = new TimelineMax();
		
		tl.to(closecontent,0.5, {autoAlpha:0},'-=0.5')
		.to(naar_gebruik,0.5, {autoAlpha:0},'-=0.5')
		.to(naar_inwinnen,0.5, {autoAlpha:0},'-=0.5')
		.to(naar_hoofdplaat,0.5, {autoAlpha:0},'-=0.5')
		.to(nextitem,0.5,  {autoAlpha:0},'-=0.5')
		.to(previtem,0.5,  {autoAlpha:0},'-=0.5')
		.to(menubutton,0.5, {css:{display:"block"}})		
		.call(loaderStatus, ['hide'])
	}


	function getorientation()
	{
		// Schermorientatie ophalen

		var orientation = "";

		var mainimgW = $(window).width();
		var mainimgH = $(window).height();

		if(mainimgW / mainimgH > 1)
		{
			orientation = "landscape";
		}
		else
		{
			orientation = "portrait";
		}

		return orientation;
	}


	function checkorientation()
	{
		console.log('checkorientation');
		// Afmetingen van hoofdplaat en marker-wrapper bepalen en aanpassen

		var mainimgW = $(mainimg).width();
		var mainimgH = $(mainimg).height();

		var windowH = window.innerHeight; 
		var windowW = window.innerWidth;


		var verhouding_scherm = windowW/windowH;
		var verhouding_image = mainimgW/mainimgH;

		if(verhouding_image >= verhouding_scherm)
		{
			$(mainimg).css({width: "auto", height: '100%', position: 'absolute'});
			$(hoverimg).css({width: "auto", height: '100%', position: 'absolute'});
			$(marker_wrapper).css({width: "auto", height: '100%'});
		}
		else
		{
			$(mainimg).css({width: '100%', height: "auto", position: 'absolute'});
			$(hoverimg).css({width: '100%', height: "auto", position: 'absolute'});
			$(marker_wrapper).css({width: '100%', height: "auto"});
		}	
	}

	function dragit()
	{
		console.log('dragit');
		// Drag functie initieren en grenzen van slepen afbakenen

		var screenw = $(main).width();
		var screenh = $(main).height();

		var imgW = $(mainimg).width();
		var imgH = $(mainimg).height();

		var startX = screenw-imgW; // ruimte over aan rechterkant
		var maxX = 0; // ruimte over aan linkerkant
		var minYPosition = screenh-imgH; // ruimte over aan onderkant
		var maxYPosition = 0; // ruimte over aan bovenkant

		// JQUERY DRAG
		$(img_wrapper).draggable({
	         containment:  [startX, minYPosition, maxX, maxYPosition ]
	    });

	  	isworking = 0;
	}

	function positionimagewrapper()
	{
		console.log('positionimagewrapper');

		// imagewrapper positioneren

		var screenw = $(main).width();
		var screenh = $(main).height();

		var mainimgW = $(mainimg).width();
		var mainimgH = $(mainimg).height();

		
		if(mainimgW > screenw)
		{
			startingleft = -(mainimgW-screenw)/2;
			startingtop = -(mainimgH-screenh)/2;
		}
		else
		{
			startingleft = 0;
			startingtop = -(mainimgH-screenh)/2;
		}

		var tl = new TimelineMax();
		
		tl.to(img_wrapper,0.5, {css:{width:currentzoom+"vw", height: currentzoom+"vh"}})
		.to(img_wrapper,0.5, {css:{left:startingleft+"px", top: startingtop+"px"}},'-=0.5')
	}

	function positionmarkers()
	{
		console.log('positionmarkers');
		// Markers op de juiste plek plaatsen

		if(fullzoomout == 0)
		{
			var mainimgW = $(mainimg).width();
			var mainimgH = $(mainimg).height();

			$(marker_wrapper).css({width: mainimgW+"px", height: mainimgH+"px"});
		}
		else
		{
			var fullimageW = $(fullimage).width();
			var fullimageH = $(fullimage).height();
			$(marker_wrapper).css({width: fullimageW+"px", height: fullimageH+"px"});		
		}
	}



	//-------------------------   Eind mapfuncties   --------------------------- //


	//-------------------------   Begin zoomfuncties   --------------------------- //
	function zoomin(amount)
	{

		// positie van imagewrapper bepalen
		var wrapperL = $(img_wrapper).position().left;
		var wrapperT = $(img_wrapper).position().top;

		var screenw = $(main).width();
		var screenh = $(main).height();

		var vergroting = amount / (amount-zoomamount);

		// Hoeveel gaat de afbeelding naar links en boven vanwege de zoom	
		var offsetPercentage = (amount-zoomamount)/startwidth*4;

		// Nieuwe posities van afbeelding
		var leftPos =  (-screenw/offsetPercentage)+(wrapperL*vergroting);
		var topPos = (-screenh/offsetPercentage)+(wrapperT*vergroting);

		// Markerwrapper meevergroten
		var enlargement = amount/(amount-zoomamount);
		var markerwrapperW = $(marker_wrapper).width();
		var markerwrapperH = $(marker_wrapper).height();

		var mainimgW = enlargement*($(mainimg).width());
		var mainimgH = enlargement*($(mainimg).height());



		// Zoomen en positioneren
		if(hascontent == 1)
		{
			var pos = "";
			pos = calc_positions(activeMarker,enlargement);

			if(pos != "")
			{
				var leftPos =  pos[0];
				var topPos = pos[1];

				if(topPos < -1*(mainimgH-screenh))
					topPos = -1*(mainimgH-screenh);
				else if(topPos > 0)
					topPos = 0;

				if(leftPos < -1*(mainimgW-screenw))
					leftPos = -1*(mainimgW-screenw);
				else if(leftPos > 0)
					leftPos = 0;

			}
		}

		var tl = new TimelineMax();
		tl.to(img_wrapper,1,  {css:{width:amount+"vw", height: amount+"vh"}},0.5)
		.to(marker_wrapper,1,  {css:{width:(markerwrapperW*enlargement)+"px", height:(markerwrapperH*enlargement)+"px"}},'-=1')
		.to(img_wrapper,1, {css:{left:leftPos+"px", top:topPos+"px"}, onComplete: dragit},'-=1')
		.to(marker_wrapper,1, {css:{left:"0px", top:"0px"}, onComplete: positionmarkers},'-=1');


	}


	function zoomout(amount)
	{

		var screenw = $(main).width();
		var screenh = $(main).height();

		// // positie van imagewrapper bepalen
		var wrapperL = $(img_wrapper).position().left;
		var wrapperT = $(img_wrapper).position().top;

		var reduction = amount/(amount+zoomamount);
		// // Indien de zijkanten zichtbaar worden herstellen
		var mainimgW = reduction*($(mainimg).width());
		var mainimgH = reduction*($(mainimg).height());

		var imgw = $(img_wrapper).width();
		var imgh = $(img_wrapper).height();

		// Deze waarde bepaald welk percentage van het scherm erbuiten valt op basis van de zoom
	 	var zoomperc = 2*(currentzoom)/zoomamount;

		var linksvanscherm = (screenw)/zoomperc;
		var topvanscherm = (screenh)/zoomperc;
		
		var leftPos = Math.round(-(-wrapperL-linksvanscherm)*reduction);
		var topPos = Math.round(-(-wrapperT-topvanscherm)*reduction);


		// Markerwrapper meevergroten
		var markerwrapperW = $(marker_wrapper).width();
		var markerwrapperH = $(marker_wrapper).height();



		if(hascontent == 1)
		{
			var pos = "";
			pos = calc_positions(activeMarker,reduction);

			if(pos != "")
			{
				var leftPos =  pos[0];
				var topPos = pos[1];
			}
		}

		// Checken dat er geen witte vlakken ontstaan
		if(topPos < -1*(mainimgH-screenh))
			topPos = -1*(mainimgH-screenh);
		else if(topPos > 0)
			topPos = 0;

		if(leftPos < -1*(mainimgW-screenw))
			leftPos = -1*(mainimgW-screenw);
		else if(leftPos > 0)
			leftPos = 0;

		
		// Zoomen en positioneren
		var tl = new TimelineMax();
		tl.to(img_wrapper,1, {css:{width:amount+"vw", height: amount+"vh"}},0.5)
		.to(marker_wrapper,1,  {css:{width:(markerwrapperW*reduction)+"px", height:(markerwrapperH*reduction)+"px"}},'-=1')
		.to(img_wrapper,1, {css:{left:leftPos+"px", top:topPos+"px"}, onComplete: dragit},'-=1')
		.to(marker_wrapper,1, {css:{left:"0px", top:"0px"}, onComplete: positionmarkers},'-=1');

	}


	function Fullimagestatus(type)
	{
		
		if(type == "show")
		{
			openFullimage();
		}
		else
		{
			closeFullimage();
		}
	}

	function openFullimage()
	{
			var tl = new TimelineMax();

			var fullimageW = $(fullimage).width();
			var fullimageH = $(fullimage).height();

			tl.to(mainimg,0.5,{css:{filter:'blur(10px)'}})
			.to(mainimg,0.5,{css:{opacity:0.4}},'-=0.5')
			.to(mainimg,0.1,{css:{cursor:"default"}},'-=0.5')
			.to(marker_wrapper,0.1,{css:{cursor:"default"}},'-=0.5')
			.to(fullimage,0.1,{css:{'z-index':"90"}},'-=1')
			.to(fullimage,0.5,{css:{opacity:1}},'-=0.5')
			.to(marker_wrapper,0.1,{css:{'z-index':"100"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'position':"fixed"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'width':fullimageW+"px"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'height':fullimageH+"px"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'top':"100px"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'left':"0px"}},'-=1')
		
			$(img_wrapper).draggable( 'disable' );
			fullzoomout = 1;
			return "done";
	}

	function closeFullimage()
	{
			var tl = new TimelineMax();

			tl.to(fullimage,0.5,{css:{opacity:0}})
			.to(fullimage,0.1,{css:{'z-index':"-1"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'z-index':"-1"}},'-=1')
			.to(marker_wrapper,0.1,{css:{cursor:"grab"}},'-=0.5')
			.to(mainimg,0.1,{css:{cursor:"grab"}},'-=0.5')
			.to(mainimg,0.5,{css:{filter:'none'}},'-=0.5')
			.to(mainimg,0.5,{css:{opacity:1}},'-=0.5')
			.to(marker_wrapper,0.1,{css:{'z-index':"1040"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'position':"absolute"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'width':"100%"}},'-=1')
			.to(marker_wrapper,0.1,{css:{'height':"auto"}, onComplete: positionmarkers},'-=1')
			.to(marker_wrapper,0.1,{css:{'top':"0px"}},'-=1')
		
			$(img_wrapper).draggable( 'enable' );
			fullzoomout = 0;
			return "done";

	}

	function resetZoom()
	{

		console.log('resetZoom');
		var amount = startwidth;
		var reduction = amount/currentzoom;
		var markerwrapperW = $(marker_wrapper).width();
		var markerwrapperH = $(marker_wrapper).height();


		var screenw = $(window).width();
		var screenh = $(window).height();


		var dif = -0.5*((reduction*markerwrapperW)-screenw);
		var	topPos = 0;
		var	leftPos = dif;

		currentzoom = startwidth;
		
		var tl = new TimelineMax();

		tl.to(img_wrapper,1, {css:{width:amount+"vw", height: amount+"vh"}},0.5)
		.to(marker_wrapper,1,  {css:{width:(markerwrapperW*reduction)+"px", height:(markerwrapperH*reduction)+"px"}},'-=1')
		.to(marker_wrapper,1,  {css:{left:"0px", top:topPos+"px"}},'-=1')
		.to(img_wrapper,1, {css:{left:leftPos+"px", top:topPos+"px"}, onComplete: dragit},'-=1');

		return "done";
	}

	function zoomTostart()
	{	
		if(isworking == 0) {

			$('#zoom .minus').css({opacity:"1", cursor: "default"})
			$('#zoomtostart').css({color:"#D57594", cursor: "default"})

			isworking = 1;	
			zoomposition = 0;
			resetZoom();

			menustatus("close");
			
		}
	}

	//-------------------------   Eind zoomfuncties   --------------------------- //


	//-------------------------   Begin markerfuncties   --------------------------- //

	function calc_positions(thisID,zoom)
	{
		// Positie van actieve marker berekenen. Deze positie wordt gecentreerd op het scherm en als centrum gebruikt bij het zoomen indien een marker actief is.
		// var Zoom bepaald te positie voordat de functie zoom wordt uitgevoerd. Zo kan bij een zoom direct naar een marker gezoomd worden.

		// Positie van gekozen marker bepalen
		var posleft = $('#'+thisID).offset().left*zoom;
		var postop = $('#'+thisID).offset().top*zoom;  


		// Huidige positie van image-wrapper	
		var currentX = $(img_wrapper).position().left*zoom;
		var currentY = $(img_wrapper).position().top*zoom;
		

		var imageWidth = $(mainimg).width()*zoom;
		var imageHeight = $(mainimg).height()*zoom;

		if(hascontent == 1)
		{
			
			// positie bepalen waar marker naartoe moet
			var marker_end_posX = 0.5*($(main).width());
			var end_of_mapX = $(main).width();
			

			var marker_end_posY = 0.5*($(main).height());
			var end_of_mapY = $(main).height();

		}
		else
		{
			// Indien er voor het eerst op een knop wordt gedruk de startwaarden opslaan om naartoe te kunnen resetten.
			startX = $(img_wrapper).position().left*zoom;
			startY = $(img_wrapper).position().top*zoom;


			var orientation = getorientation();

			if(orientation == "landscape")
			{
				// positie bepalen waar marker naartoe moet
				var marker_end_posX = 0.5*0.5*($(main).width())*zoom;
				var end_of_mapX = 0.5*$(main).width()*zoom;

				var marker_end_posY = 0.5*($(main).height())*zoom;
				var end_of_mapY = $(main).height()*zoom;
			}
			else
			{
				// positie bepalen waar marker naartoe moet
				
				var currentX = $(img_wrapper).position().left*zoom;
				var currentY = $(img_wrapper).position().top*zoom;

				var posleft = $('#'+thisID).offset().left*zoom;
				var postop = $('#'+thisID).offset().top*zoom;  


				var marker_end_posX = $(main).width()*0.5;
				var end_of_mapX = $(main).width();
				

				var marker_end_posY = $(main).height()*0.5*0.5;
				var end_of_mapY = $(main).height()*0.5;

			}

		}

		// Check X
		var differenceleft = Math.round(marker_end_posX-posleft);	  // Klopt
		var newposX = currentX+differenceleft;
		var maxX = newposX+imageWidth;



		if(newposX > 0)  // Uiterst links
			newposX = 0;
		else if(maxX < end_of_mapX)  // Uiterst rechts
			newposX = end_of_mapX - imageWidth;
		
		

		// Check Y
		var differencetop = Math.round(marker_end_posY-postop);	
		var newposY = currentY+differencetop;
		var maxY = newposY+imageHeight;


		if(newposY > 0)  // Uiterst boven
			newposY = 0;
		else if(maxY < end_of_mapY)  // Uiterst rechts
			newposY = end_of_mapY - imageHeight;

		var positions = [newposX,newposY];

		return positions;
	}

	function wrappermover(thisID)
	{
		// De tekst van een marker laden

		closeFullimage();
		var pos = "";
		pos = calc_positions(thisID,1);

		if(pos != "")
		{
			var newposX = pos[0];
			var newposY = pos[1];
			moveWrapper(newposX,newposY,thisID);
	    };	
	}




	function moveWrapper(posleft,postop,id)
	{

		// De tekst van een marker laden
		
		activeMarker = id;
		hascontent = 1;
			
		var tl = new TimelineMax();

		var orientation = getorientation();
		if(orientation == "landscape")
		{
			tl.to(contentcontainer,0.5,  {autoAlpha:0})
			.to(closecontent,0.5, {autoAlpha:0},'-=0.5')
			.to(naar_hoofdplaat,0.5, {autoAlpha:0},'-=0.5')
			.to(naar_inwinnen,0.5, {autoAlpha:0},'-=0.5')
			.to(naar_gebruik,0.5, {autoAlpha:0},'-=0.5')
			.to(zoom,0.5,  {autoAlpha:0},'-=0.5')
			.to(nextitem,0.5,  {autoAlpha:0},'-=0.5')
			.to(previtem,0.5,  {autoAlpha:0},'-=0.5')
			.to(menubutton,0.5,  {autoAlpha:0},'-=0.5')
			.to(main,1,  {css:{height:"100vh"}})
			.to(main,1,  {css:{width:"50vw"}},'-=1')
			.to(img_wrapper,1, {css:{left:(posleft)+"px", top:(postop)+"px"}, onComplete: positionmarkers},'-=1')
			.to(content,1, {css: {width: "50vw", top: "0px"}, onComplete: dragit}, '-=1')
			.call(loadcontent,[id,'contentcontainer'],'-=1')
			.to(zoom,0.5,  {css:{bottom:"0px" ,"margin-bottom": "20px"}})
			.to(zoom,0.5,  {css:{right:"50%", "margin-right": "20px"}},'-=0.5')
			.to(zoom,0.5,  {autoAlpha:1},'-=0.5')
			.add(active_page)
			.to(pages,0.5, {autoAlpha:1},'-=0.5')
			.to(contentcontainer,0.5, {autoAlpha:1},'-=0.5')
			.to(content,0.5, {css:{"z-index":"1050"}},'+=0.5')
			.to(naar_gebruik,0.5, {autoAlpha:1},'-=0.5')
			.to(naar_inwinnen,0.5, {autoAlpha:1},'-=0.5')
			.to(naar_hoofdplaat,0.5, {autoAlpha:1},'-=0.5')
			.to(nextitem,0.5,  {autoAlpha:1},'-=0.5')
			.to(previtem,0.5,  {autoAlpha:1},'-=0.5')
			.to(closecontent,0.5, {autoAlpha:1},'-=0.5');

			// isworking = 0;

		}
		else
		{

			tl.to(contentcontainer,0.5,  {autoAlpha:0})
			.to(marker_wrapper,0.5, {autoAlpha:0},'-=0.5')
			.to(closecontent,0.5, {autoAlpha:0},'-=0.5')
			.to(naar_hoofdplaat,0.5, {autoAlpha:0},'-=0.5')
			.to(naar_inwinnen,0.5, {autoAlpha:0},'-=0.5')
			.to(naar_gebruik,0.5, {autoAlpha:0},'-=0.5')
			.to(zoom,0.5,  {autoAlpha:0},'-=0.5')
			.to(nextitem,0.5,  {autoAlpha:0},'-=0.5')
			.to(previtem,0.5,  {autoAlpha:0},'-=0.5')
			.to(menubutton,0.5,  {autoAlpha:0},'-=0.5')
			.to(main,1,  {css:{width:"100vw"}})
			.to(main,1,  {css:{height:"50vh"}, onComplete: positionmarkers},'-=1')
			.to(img_wrapper,1, {css:{left:(posleft)+"px", top:(postop)+"px"}, onComplete: dragit},'-=1')
			.to(content,1, {css: {width: "100vw", top: "50%"}}, '-=1')
			.call(loadcontent,[id,'contentcontainer'],'-=1')
			.to(zoom,0.5,  {css:{bottom:"50%" ,"margin-bottom": "20px"}})
			.to(zoom,0.5,  {css:{right:"0px", "margin-right": "20px"}})
			.to(zoom,0.5,  {autoAlpha:1})
			.add(active_page)
			.to(pages,0.5, {autoAlpha:1},'-=0.5')
			.to(contentcontainer,0.5, {autoAlpha:1},'-=0.5')
			.to(marker_wrapper,0.5, {autoAlpha:1},'-=0.5')
			.to(content,0.5, {css:{"z-index":"1050"}},'+=0.5')
			.to(naar_gebruik,0.5, {autoAlpha:1},'-=0.5')
			.to(naar_inwinnen,0.5, {autoAlpha:1},'-=0.5')
			.to(naar_hoofdplaat,0.5, {autoAlpha:1},'-=0.5')
			.to(nextitem,0.5,  {autoAlpha:1},'-=0.5')
			.to(previtem,0.5,  {autoAlpha:1},'-=0.5')
			.to(closecontent,0.5, {autoAlpha:1},'-=0.5');

			// isworking = 0;
		}


	}

	function resetWrapper()
	{
		$('.marker').removeClass('animate-button');

		hascontent = 0;
		activeMarker = 0;

		console.log(isworking);

		var tl = new TimelineMax();
		var orientation = getorientation();
		if(orientation == "landscape")
		{		

			tl.to(content,0.5, {css:{"z-index":"1010"}})
			.add(zoomTostart,'+=0.5')
			.to(contentcontainer,0.5,  {autoAlpha:0})
			.to(closecontent,0.5, {css:{opacity:0}},'-=0.5')
			.to(naar_gebruik,0.5, {css:{opacity:0}},'-=0.5')
			.to(naar_inwinnen,0.5, {css:{opacity:0}},'-=0.5')
			.to(naar_hoofdplaat,0.5, {css:{opacity:0}},'-=0.5')
			.to(pages,0.5,{autoAlpha:0},'-=0.5')
			.to(gototop,0.5, {autoAlpha:0},'-=0.5')
			.to(zoom,0.5, {autoAlpha:0},'-=0.5')
			.to(nextitem,0.5,  {autoAlpha:0},'-=0.5')
			.to(previtem,0.5,  {autoAlpha:0},'-=0.5')
			.to(img_wrapper,1, {css:{left:(startingleft)+"px", top:startingtop+"px"}})
			.to(main,1,  {css:{width:"100vw"}, onComplete: dragit},'-=1')
			.to(main,1,  {css:{height:"100vh"}},'-=1')
			.to(zoom,0.5,  {css:{right:" 20px"}})
			.to(zoom,0.5,  {css:{bottom:" 20px"}},'-=0.5')
			.to(zoom,0.5,  {autoAlpha:1})
			.to(menubutton,0.5,  {autoAlpha:1},'-=0.5');
		}
		else
		{

			tl.to(content,0.5,  {css:{"z-index":"1010"}})
			.add(zoomTostart)
			.to(contentcontainer,0.5,  {autoAlpha:0})
			.to(marker_wrapper,0.5, {autoAlpha:0},'-=0.5')
			.to(closecontent,0.5,  {autoAlpha:0},'-=0.5')
			.to(naar_hoofdplaat,0.5,  {autoAlpha:0},'-=0.5')
			.to(naar_inwinnen,0.5,  {autoAlpha:0},'-=0.5')
			.to(naar_gebruik,0.5,  {autoAlpha:0},'-=0.5')
			.to(pages,0.5, {autoAlpha:0},'-=0.5')
			.to(gototop,0.5,  {css:{opacity:0}},'-=0.5')
			.to(zoom,0.5,  {autoAlpha:0},'-=0.5')
			.to(nextitem,0.5,  {autoAlpha:0},'-=0.5')
			.to(previtem,0.5,  {autoAlpha:0},'-=0.5')			
			.to(main,1,  {css:{height:"100vh"}})
			.to(img_wrapper,1, {css:{left:(startingleft)+"px", top:"0px"}, onComplete: dragit})
			.to(content,1, {css: {width: "50vw", top: "0px"}}, '-=1')
			.to(zoom,0.5,  {css:{bottom:" 20px"}, onComplete: positionmarkers})
			.to(zoom,0.5,  {css:{right:" 20px"}},'-=0.5')
			.to(zoom,0.5,  {autoAlpha:1})
			.to(menubutton,0.5,  {autoAlpha:1},'-=0.5')
			.to(marker_wrapper,0.5, {autoAlpha:1},'-=0.5');
		}	
	}

	function active_page()
	{
		if(activeMarker == "marker6" || activeMarker == "marker10" || activeMarker == "marker14")
			$('#nextitem').css({opacity: "0.5", cursor: "default"});
		else
			$('#nextitem').css({opacity: "1", cursor: "pointer"});

		if(activeMarker == "marker1" || activeMarker == "marker7" || activeMarker == "marker11")
			$('#previtem').css({opacity: "0.5", cursor: "default"});
		else
			$('#previtem').css({opacity: "1", cursor: "pointer"});

	}


	function hidemarkers()
	{
		var tl = new TimelineMax();
		tl.to(".marker", 0.5, {autoAlpha:0})

		presentation_modus = 1;
	}


	function showmarkers()
	{
		var tl = new TimelineMax();
		tl.to(".marker", 0.5, {autoAlpha:1})

		presentation_modus = 0;
	}


// ONCLICKS ALGEMEEN //


$('#langbutton').mousedown(function(){
	changelanguage();
});

$('#menubutton').mousedown(function(){
	menustatus("show");
});


$('#closemenu').mousedown(function(){
	menustatus("close");
});

$('#fullscreen').mousedown(function(){

	if(fullscreen == 0) {
		switch_btn_on('fs_mode');
		openFullscreen();
		fullscreen = 1;
	}
	else {
		switch_btn_off('fs_mode');
		closeFullscreen();
		fullscreen = 0;
	}
});


$('#gototop').mousedown(function(){
	$(content).animate({ scrollTop: 0 }, "slow");
	return false;
});

$('#gototop_reader').mousedown(function(){
	$(info).animate({ scrollTop: 0 }, "slow");
	return false;
});


$('#closeinfo').mousedown(function(){
	closeinfo();
	menustatus("close");
});

$('#gotoinfo').mousedown(function(){
	showinfo(infoid,'infocontainer');
	menustatus("close");
});

$('.gotoreader').mousedown(function(){
	showinfo(readerid,'infocontainer');
	menustatus("close");
});


$('#share').mousedown(function(){
	showshare();
	menustatus("close");
});

$('#closeshare').mousedown(function(){
	closeshare();
	menustatus("close");
});


$('#sendshare').mousedown(function(){
	share();
});

$('#home').mousedown(function(){
	gotohome();
	menustatus("close");
});

// Laat map zien
$('.letsgo').mousedown(function(){
	closeinfo()
	letsgo();
	menustatus("close");
});



// ONCLICKS ZOOMS //
$('#zoomin').mousedown(function(){
	

	if(zoomposition < 0 && isworking == 0)
	{
		zoomposition++;
		Fullimagestatus('hide');
	}
	else if(zoomposition < maxzoom && isworking == 0)
	{
		isworking = 1;
		zoomposition++;
		currentzoom = startwidth+(zoomposition*zoomamount);
		zoomin(currentzoom);
	}
	
	// css van zoomknoppen aanpassen
	if(zoomposition >= 0)
	{
		$('#zoom .minus').css({opacity:"1", cursor: "zoom-out"})
	}
	if(zoomposition > 0)
	{
		$('#zoomtostart').css({color:"#C81246", cursor: "pointer"})
	}


	if(zoomposition == maxzoom)
		$('#zoom .plus').css({opacity:"0.5", cursor: "default"})

	isworking = 0;

});



$('#zoomout').mousedown(function(){

	if(zoomposition > 0  && isworking == 0) {
		isworking = 1;	
		zoomposition--;
		currentzoom = currentzoom-zoomamount;
		zoomout(currentzoom);
	}
	else if(zoomposition == 0 && isworking == 0)
	{
		var orientation = getorientation();

		// Op mobiel niet geheel uitzoomen. Dit werkt niet goed.
		if(orientation == "landscape")
		{
			zoomposition--;
			Fullimagestatus('show');
		}
	}

	// css van zoomknoppen aanpassen
	if(zoomposition == -1)
	{
		$('#zoom .minus').css({opacity:"0.5", cursor: "default"})
		$('#zoomtostart').css({color:"#C81246", cursor: "pointer"})
	}	

	if(zoomposition == 0)
	{
		$('#zoomtostart').css({color:"#D57594", cursor: "default"})
		
		var orientation = getorientation();
		if(orientation == "portrait")
			$('#zoom .minus').css({opacity:"0.5", cursor: "default"})
	
	}

	if(zoomposition < maxzoom)
		$('#zoom .plus').css({opacity:"1", cursor: "zoom-in"})

	isworking = 0;

});


$('#zoomtostart').mousedown(function(){

	if(zoomposition < 0 && isworking == 0)
	{
		zoomposition++;
		resetWrapper();
		Fullimagestatus('hide');
	}
	else
	{
		zoomTostart();
	}
});

// ONCLICKS MARKERS //

$('#presentation_modus').mousedown(function(){
	if(presentation_modus == 0)
	{
		hidemarkers();
		switch_btn_on('pres_mode');
	}
	else
	{
		showmarkers();
		switch_btn_off('pres_mode');
	}
});

$('.marker').mousedown(function(){

	// Get ID op marker
	var thisID = $(this).attr('id');

	activeMarker = thisID;

	// Actieve marker animeren
	$('.marker').removeClass('animate-button');
	$(this).addClass('animate-button');

	wrappermover(thisID);
});

$('#nextitem').mousedown(function(){

	if(activeMarker != "marker6")
	{
		// Get ID op marker
		var thisID = activeMarker.replace('marker','');

		var nextMarker = parseInt(thisID, 10)+1;
		thisID = 'marker'+ nextMarker;
		activeMarker = thisID;

		// Actieve marker animeren
		$('.marker').removeClass('animate-button');
		$('#'+thisID).addClass('animate-button');

		wrappermover(thisID);
	}	

	isworking = 1;
	
});


$('#previtem').mousedown(function(){

	if(activeMarker != "marker1")
	{
		// Get ID op marker
		var thisID = activeMarker.replace('marker','');

		var nextMarker = parseInt(thisID, 10)-1;
		thisID = 'marker'+ nextMarker;
		activeMarker = thisID;

		// Actieve marker animeren
		$('.marker').removeClass('animate-button');
		$('#'+thisID).addClass('animate-button');

		wrappermover(thisID);
	}	

	isworking = 1;

});

$('#naar_hoofdplaat').mousedown(function(){

	// Get ID op marker
	var thisID = "marker1";

	activeMarker = thisID;

	// Actieve marker animeren
	$('.marker').removeClass('animate-button');
	$('#'+thisID).addClass('animate-button');


	wrappermover(thisID);
});

$('#naar_inwinnen').mousedown(function(){

	// Get ID op marker
	var thisID = "marker7";

	activeMarker = thisID;

	// Actieve marker animeren
	$('.marker').removeClass('animate-button');
	$('#'+thisID).addClass('animate-button');


	wrappermover(thisID);
});

$('#naar_gebruik').mousedown(function(){

	// Get ID op marker
	var thisID = "marker11";

	activeMarker = thisID;

	// Actieve marker animeren
	$('.marker').removeClass('animate-button');
	$('#'+thisID).addClass('animate-button');


	wrappermover(thisID);
});

$('.text_hoofdplaat').mousedown(function(){
	var infoid = 15;
	showinfo(infoid,'infocontainer');

	$('.info_menu').removeClass('active');
	$('.text_hoofdplaat').addClass('active');

});


$('.text_inwinnen').mousedown(function(){
	var infoid = 18;
	showinfo(infoid,'infocontainer');
	$('.info_menu').removeClass('active');
	$('.text_inwinnen').addClass('active');

});


$('.text_gebruik').mousedown(function(){
	var infoid = 19;
	showinfo(infoid,'infocontainer');
	
	$('.info_menu').removeClass('active');
	$('.text_gebruik').addClass('active');

});

// Sluiten van content
$('#closecontent').mousedown(function(){
	resetWrapper();
});